/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { useFormik } from 'formik';
import Accordion from '../components/Accordion/Accordion';
import CountUp from '../components/CountUp/CountUp';
import Layout from '../components/Layout';
import LogoHeader from '../components/Header/LogoHeader';

import SEO from '../components/SEO';
import { MerchantSchema } from '../components/FormValidation';
import {
  faqData,
  stats,
  heroLogoData,
  describeOptions,
} from '../content/merchant-support';
import flag from '../images/india-flag.png';
import '../styles/merchant-support.scss';
import Select from '../components/Input/Select/Select';
import LogoMarquee from '../components/LogoMarquee/LogoMarquee';

const initialValues = {
  name: '',
  email: '',
  phone: '',
  countries: '',
  message: '',
  describe_yourself: '',
};

const MerchantSupport = (props) => {
  const {
    values,
    setFieldValue,
    handleChange,
    handleSubmit,
    errors,
    touched,
  } = useFormik({
    initialValues,
    validationSchema: MerchantSchema,
    onSubmit: (value, action) => {
      action.resetForm();
    },
  });

  return (
    <Layout {...props} noHeader noFooter>
      <SEO
        title="Merchant Support | Cashfree Payments"
        url="https://www.cashfree.com/merchant-support"
        keywords={['merchant-support']}
      />
      <LogoHeader />
      <section className="flex lg:min-h-screen sm:h-auto flex-col merchant-bg">
        <section className=" flex flex-col-reverse md:flex-row flex-wrap flex-1 merchant">
          <div className="flex flex-col merchant-support py-[48px] md:pt-[111px] bg-cf-light-grey md:pb-[128px]">
            <div className="support-padding">
              <div className="text-[16px] text-base md:text-3md mb-0 md:mb-6md font-semibold">
                Need support?
              </div>
              <div className="mt-4md md:mt-6md mb-12md  md:mb-8md p-4md md:p-6md pb-4 md:pb-[40px] bg-white rounded bg-[url(/img/merchant-support/request-bg-mb.svg)] lg:bg-[url(/img/merchant-support/request-bg.svg)] bg-right-bottom bg-no-repeat transition-all duration-300 md:hover:shadow-card focus:shadow-card ">
                <div className="font-semibold mb-1md md:mb-3md">
                  I made payment via Cashfree Payments
                </div>
                <div className="pr-[77px] md:pr-[94px]">
                  I made a transaction using Cashfree Payments and want to{' '}
                  <a
                    className="text-cf-green underline hover:underline"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.cashfree.com/en/report-issue/">
                    raise a request
                  </a>
                </div>
              </div>
              <div className="text-[16px] text-base md:text-3md mb-0 font-semibold flex justify-between">
                FAQs
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.cashfree.com/help/hc"
                  className="button button-unstyled btn p-0 !text-cf-green group left-[83px] !right-auto min-w-max">
                  View all FAQs
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 7 11"
                    className="group-hover:translate-x-[2px] transition-transform duration-300 ml-2.5 flex-shrink-0 w-[7px]">
                    <path
                      fill="none"
                      stroke="#05C16E"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M1 10l5-4.5L1 1"
                    />
                  </svg>
                </a>
              </div>

              <Accordion
                data={faqData}
                className="merchant-accordian root "
                border
                colored
                defaultOpen={false}
                arrow="chevron-current"
              />
            </div>
          </div>
          <div className="flex flex-col merchant-form pt-[90px] md:pt-[87px] pb-[48px] md:pb-4md bg-white">
            <div className="form-container">
              <div className="form-header">
                <h2 className="text-shs md:text-shl mb-2md md:mb-3md font-semibold text-cf-hero">
                  Merchant Support
                </h2>
                <p className="md:max-w-[450px] mb-6md md:mb-12md text-2.5sm md:text-base">
                  Find answers to queries related to account activation,
                  products, pricing, settlements etc.
                </p>
              </div>

              <div className="form-wrapper">
                <form
                  className="text-sm font-semibold mb-3"
                  onSubmit={handleSubmit}>
                  <div className="mb-6md">
                    <label
                      htmlFor="name"
                      className="block mb-2 text-sm text-cf-hero">
                      Your Name
                      <input
                        type="text"
                        id="name"
                        className={
                          errors.name && touched.name
                            ? 'border-red-500 border text-2.5sm md:text-2sm rounded focus:ring-red-500 focus:border-red-500 block w-full p-2.5 h-[48px] md:h-[46px] font-light mt-1'
                            : 'border text-2.5sm md:text-2sm rounded focus:ring-green-500 focus:border-green-500 block w-full p-2.5 h-[48px] md:h-[46px] font-light mt-1'
                        }
                        placeholder="Rahul Sharma"
                        name="name"
                        autoComplete="off"
                        value={values.name}
                        onChange={handleChange}
                      />
                    </label>
                    {errors.name && touched.name && (
                      <p className="font-light text-cf-red-400">
                        {errors.name}
                      </p>
                    )}
                  </div>
                  <div className="mb-6md">
                    <label
                      htmlFor="email"
                      className="block mb-2 text-sm text-cf-hero">
                      Registered business email ID
                      <input
                        type="email"
                        id="email"
                        className={
                          errors.email && touched.email
                            ? 'border-red-500 border text-2.5sm md:text-2sm  rounded focus:ring-red-500 focus:border-red-500 block w-full p-2.5 h-[48px] md:h-[46px] font-light mt-1'
                            : 'border text-2.5sm md:text-2sm  rounded focus:ring-green-500 focus:border-green-500 block w-full p-2.5 h-[48px] md:h-[46px] font-light mt-1'
                        }
                        placeholder="rahulsharma@cashfree.com"
                        name="email"
                        autoComplete="off"
                        value={values.email}
                        onChange={handleChange}
                      />
                    </label>
                    {errors.email && touched.email && (
                      <p className="font-light text-cf-red-400">
                        {errors.email}
                      </p>
                    )}
                  </div>
                  <div className="mb-6md">
                    <label
                      htmlFor="phone"
                      className=" relative block mb-2 text-sm text-cf-hero">
                      Phone Number
                      <div className="flex mt-1 relative">
                        <div className="flex mt-[1px] pt-0 items-center h-[46px] md:h-[44px] absolute border-r">
                          <img className="px-2" src={flag} alt="" />
                          <p className="pr-2 text-cf-hero">91+</p>
                        </div>
                        <input
                          type="tel"
                          id="phone"
                          className={
                            errors.phone && touched.phone
                              ? 'border-red-500 border text-2.5sm md:text-2sm  rounded focus:ring-red-500 focus:border-red-500 block w-full p-2.5 h-[48px] md:h-[46px] font-light pl-20'
                              : 'border text-2.5sm md:text-2sm  rounded focus:ring-green-500 focus:border-green-500 block w-full p-2.5 h-[48px] md:h-[46px] font-light pl-20'
                          }
                          placeholder="9793067777"
                          name="phone"
                          autoComplete="off"
                          value={values.phone}
                          onChange={handleChange}
                        />
                      </div>
                    </label>
                    {errors.phone && touched.phone && (
                      <p className="font-light text-cf-red-400">
                        {errors.phone}
                      </p>
                    )}
                  </div>
                  <div className="mb-6md">
                    <label
                      htmlFor="describe_yourself"
                      className="block mb-2 text-sm text-cf-hero">
                      Describe yourself briefly
                      <Select
                        className="w-full mt-1"
                        id="describe_yourself"
                        hasError={
                          errors.describe_yourself && touched.describe_yourself
                        }
                        placeholder=" Choose what describes you best"
                        options={describeOptions}
                        onChange={({ value }) =>
                          setFieldValue('describe_yourself', value)
                        }
                      />
                    </label>
                    {errors.describe_yourself && touched.describe_yourself && (
                      <p className="font-light text-cf-red-400">
                        {errors.describe_yourself}
                      </p>
                    )}
                  </div>
                  <div className="mb-6md">
                    <label
                      htmlFor="message"
                      className="block mb-2 text-sm text-cf-hero">
                      Message
                      <textarea
                        id="message"
                        rows="4"
                        className={
                          errors.message && touched.message
                            ? 'border-red-500 border text-2.5sm md:text-2sm  rounded focus:ring-red-500 focus:border-red-500 block w-full p-2.5  font-light mt-1'
                            : 'border text-2.5sm md:text-2sm  rounded focus:ring-green-500 focus:border-green-500 block w-full p-2.5 font-light mt-1'
                        }
                        placeholder="Tell us about your business, specific needs, and timelines "
                        name="message"
                        value={values.message}
                        onChange={handleChange}
                      />
                    </label>
                    {errors.message && touched.message && (
                      <p className="font-light text-cf-red-400">
                        {errors.message}
                      </p>
                    )}
                  </div>
                  <button
                    type="submit"
                    className="button bg-cf-green button-green inline-block md:mb-0 w-full md:w-auto text-white">
                    Submit <span className="chevron" />
                  </button>
                  <br />
                </form>
                <i className="text-tiny">
                  Track status of your customer&apos;s transaction.{' '}
                  <u>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-cf-green underline hover:underline"
                      href="https://www.cashfree.com/en/report-issue/">
                      Click here
                    </a>
                  </u>
                </i>
              </div>
            </div>
          </div>
        </section>
      </section>
      <section className="bg-cf-primary py-[60px] merchant-trusted relative overflow-hidden">
        <div className="container text-white relative z-[1]">
          <div className=" text-shs md:text-shl  font-heading font-semibold text-center mb-8md md:mb-[40px]">
            Trusted by businesses, Loved by developers
          </div>

          <div className="flex flex-wrap mx-[-21px]  md:mx-auto max-w-[700px]">
            {stats.map((i) => (
              <div key={i.id} className="px-3.5 w-1/3">
                <div className="h-full flex flex-col justify-start">
                  <div className="text-base md:text-shs font-bold md:font-semibold text-center md:leading-[30px]">
                    <CountUp
                      {...i.countUpProps}
                      duration={1.4}
                      ease="circ.out"
                      alwaysCenter={false}
                    />
                  </div>
                  <div className="leading-4 md:leading-6 md:mt-1 text-tiny md:text-base text-cf-light-cold-purple text-center">
                    {i.desc}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="logo-container  mt-12md md:mt-16">
          <div className="container">
            <div className="mb-3md md:mb-5md relative z-[1] text-2.5sm md:text-base md:text-[16px] font-semibold text-white  text-center max-w-[250px] md:max-w-max mx-auto">
              Join high growth businesses that trust Cashfree Payments
            </div>

            <div className="relative overflow-hidden z-[1]">
              <LogoMarquee data={heroLogoData} imgUrl="/img/merchant-support" />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default MerchantSupport;
